<template>
    <div>
        <div class="holder">
            <div>

            </div>
            <div class="landlord-infor-read" v-if="landlordDetails === 'view'">
                <div class="tab-holder">
                  <button :class="[tab === 1 ? 'activeTab' : '']" class="btn gen" @click="tab = 1">General information</button>
                  <button :class="[tab === 2 ? 'activeTab' : '']" class="btn prop" @click="tab = 2; readyEdit = false">Properties</button>
                </div>
            </div>
            <div class="detail-tabs-holder" v-if="tab === 2 && propertyDetails">
              <div class="detail-tabs">
                <button v-for="item in pills"
                  :key="item.name" class="btn" :class="[pillsName === item.name ? 'active-pills' : '']" @click="pillsName = item.name">{{ item.name }}</button>
              </div>
            </div>

            <!-- <div>
              <p>tab: {{ tab }}</p>
              <p>Prop Details: {{ propertyDetails }}</p>
              <p>ready Edit: {{ readyEdit }}</p>
              <p>Pills name: {{ pillsName }}</p>
            </div> -->
            <div class="m-auto text-center mt-4" v-if="loading"><Loader/></div>
            <div v-else>
              <div v-if="tab === 1">
                <div class="" v-if="landlordDetails === 'view'">    
                  <div class="email-container info-holder">
                    <div class="review-top-text mb-4">
                      <h5>General Information</h5>
                      <h6 @click="landlordDetails = 'edit'" v-if="landlordInfo.verified == 0">Edit</h6>
                    </div>
                    <div class="row general-info-content">
                      <div class="col-md-6">
                        <h6>Name:</h6>
                        <h5>{{ landlordInfo.firstname }} {{ landlordInfo.lastname }}</h5>
                      </div>
                      <div class="col-md-6">
                        <h6>Email address:</h6>
                        <h5>{{ landlordInfo.email }}</h5>
                      </div>
                      <div class="col-md-6">
                        <h6>Phone number:</h6>
                        <h5>{{ landlordInfo.phone }}</h5>
                      </div>
                      <div class="col-md-6">
                        <h6>Landlord Address:</h6>
                        <h5>{{ landlordInfo.address }}</h5>
                      </div>
                      <div class="col-md-6" v-if="landlordID != null">
                        <h6>Landlord's Id:</h6>
                        <h5 class="view-id-btn" data-toggle="modal" data-target="#landlordIdOpen">View landLord's Id</h5>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="green-yellow-btn flex" style="justify-content: end;">
                    <button class="btn cancel" @click="backToLandlordList()">
                      Back
                    </button>
                  </div> -->
                </div>
                <div  v-if="landlordDetails === 'edit'">
                  <div class="update-top-text" style="display: block;">
                    <h4>Edit landlord’s information</h4>
                    <h6>Update landlord details as required</h6>
                  </div>

                  <div class="form">
                    <div class="input-group">
                      <BaseInput
                        placeholder="Michael"
                        label="First Name"
                        :value="firstName"
                        @input="firstName = $event"
                        :errorMessage="errorMessage.firstName"
                        @blur="validateFirstName"
                      />
                      <BaseInput
                        label="Last Name"
                        placeholder="Ndubuisi"
                        :value="lastName"
                        @input="lastName = $event"
                        :errorMessage="errorMessage.lastName"
                        @blur="validateLastName"
                      />
                    </div>
                    <div class="input-group">
                      <BaseInput
                        type="email"
                        placeholder="example@gmail.com"
                        label="Email Address"
                        :value="email"
                        @input="email = $event"
                        :errorMessage="errorMessage.email"
                        @blur="validateEmail"
                      />

                      <BaseInput
                        label="Phone number"
                        placeholder="+2348 1437 434"
                        :value="phone"
                        type="number"
                        @input="phone = $event"
                        :errorMessage="errorMessage.phone"
                        @blur="validatePhone"
                      />

                    </div>

                    <BaseInput
                      type="textarea"
                      label="Landlord address"
                      placeholder="Enter full address"
                      :value="address"
                      @input="address = $event"
                      :errorMessage="errorMessage.address"
                      @blur="validateLandlordAddress"
                    />

                    <div class="input-group">
                      <div class="upload-btn-id">
                        <p for="" class="">Select landlord Id</p>
                        <button class="btn" @click="selectLandlordId()">{{ landlordIdFile.name ? landlordIdFile.name : "Upload landlord's ID." }}</button>
                        <!-- <BaseButtton class="btn" @click="selectLandlordId()">Upload Landlord's ID.</BaseButtton> -->
                        <input type="file" ref="landlordIdRef" @change="handleLandlordId($event)" class="d-none" >
                      </div> 
                      <!-- <div class="upload-btn-id" v-if="landlordID">
                        <p for="" class="">View landlord Id</p>
                        <button class="btn" data-toggle="modal" data-target="#landlordIdOpen">Click to view landlord's ID</button>
                      </div> -->
                     
                      
                    </div>
                  </div>

                  <div class="btn-container">
                    <BaseButtton type="text" @click="tab = 1; landlordDetails = 'view'; pillsName = 'Overview'">Cancel</BaseButtton>
                    <BaseButtton :disabled="updatingLandlordLoading" @click="updateLandlordDetails()">
                      {{ updatingLandlordLoading ? "Updating..." : "Update" }}
                    </BaseButtton>
                  </div>
                </div>
              </div>

               <!-- Modal for updating property images starts -->
               <div class="modal fade" id="landlordIdOpen" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Landlord's ID</h5>
                                <button type="button" class="close">
                                  <span class="plus" aria-hidden="true" data-dismiss="modal">X</span>
                                </button>
                            </div>
                            <div class="modal-body">
                               
                                <form style="position: relative;">
                                  <div class="container">
                                    <div class="view-image-holder" style="text-align: center;">
                                        <img class="landlordid-image" :src="landlordID" alt="landlord id">
                                    </div>
                                  </div>
                                </form>
                            </div>
                            <div class="modal-footer update-buttons">
                                <button type="button" class="btn cancel2  btn-block" data-dismiss="modal">CLOSE</button> 
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal for updating property images ends -->

              <div class="" v-if="tab === 2 && !propertyDetails &&  pillsName === 'Overview'">
                <div class="update-top-text" style="display: block;">
                    <h4>Properties</h4>
                    <h6>View and manage the list of properties owned by the landlord</h6>
                </div>
                <Info></Info>
                <div class="email-container info-holder" v-for="(item, index) in landlordPropertyLists" :key="index">
                  <div class="general-info-content mb-0">
                    <div class=" mb-0">
                      <div class="prop-review-holder">
                        <div class="indicator">
                          <h4>{{ item.guaranteed_rent == 'YES' ? 'G' : item.harmonised_rent == 'YES' ? 'H' : 'N' }}</h4>
                          <div >
                            <h5>{{ item.title }}</h5>
                            <h6>{{ item.address }}</h6>
                            <h6>{{ item.unit_count }} Units</h6>
                          </div>
                          
                        </div>
                        <div>
                          <img class="dropdown-toggle" src="../assets/icons/menu-icon.svg" alt="menu" data-toggle="dropdown" aria-expanded="false">
                          <div class="dropdown-menu">
                            <a @click="viewLandlordDetails(item)" class="dropdown-item">View details</a>
                            <a v-if="item.property_units[0].typename == 'Residential'" class="dropdown-item" @click="addUnitToProperty(item.batch)">Add unit</a>
                            <!-- <a class="dropdown-item" @click="addNewProperty(); addUnitToPrperty(item.id)">Add unit</a> -->
                            <a class="dropdown-item">Generate report</a>
                            <a class="dropdown-item">Delete</a>
                          </div>
                        </div>
                      </div>
                    </div>        
                  </div>
                </div>

                <div class="no-prop-add" @click="addNewProperty()">
                <h2>+</h2>
                <h6>Add Property</h6>
              </div>
              </div>

              <div class="" v-if="tab === 2 && propertyDetails && !readyEdit && pillsName === 'Overview'">
                <div class="update-top-text">
                    <div>
                      <h4>Properties</h4>
                      <h6>View key details and status of this property</h6>
                    </div>
                    <span @click="triggerEditProperty()" v-if="singlePropertyDetails.property_units[0].verified == 0">Edit</span>
                </div>
                <div class="property-details-view-holder property-basic-info">
                  <h4>Basic info</h4>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Property title</h6>
                        <h5>{{singlePropertyDetails.title || 'No property title'}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Property type</h6>
                        <h5>{{singlePropertyDetails.property_units[0].typename}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Property subtype</h6>
                        <h5>{{singlePropertyDetails.property_units[0].subtypename}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Business intent</h6>
                        <h5>{{singlePropertyDetails.property_units[0].businesstypename}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>State</h6>
                        <h5>{{ singlePropertyDetails.property_units[0].state }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>City</h6>
                        <h5>{{ singlePropertyDetails.property_units[0].city }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Address</h6>
                        <h5>{{ singlePropertyDetails.address }}</h5>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="">
                        <h6>Description</h6>
                        <h5>{{ singlePropertyDetails.property_units[0].description }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="property-details-view-holder property-units mt-5">
                  <h4>Units</h4>
                  <div class="border-line-bottom" v-for="(item, index) in singlePropertyDetails.property_units" :key="index">
                    <div class="row"  style="">
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>Unit name</h6>
                          <h5>{{ item.nomenclature_serial_Number }}</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>Unit Amount</h6>
                          <h5>₦{{ Number(item.price).toLocaleString() }}</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>No of bedrooms</h6>
                          <h5>{{ item.bedrooms }}</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>No of bathrooms</h6>
                          <h5>{{ item.bathrooms }}</h5>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-2">
                          <h6>Staus</h6>
                          <h5 style="text-transform: capitalize;">{{ item.status }}</h5>
                        </div>
                      </div>
                      <hr>
                    </div>
                    <hr v-if="item.length > 1 && index !== item.length - 1">
                  </div>
                  <!-- <hr> -->

                 
                  
                </div>
                <div class="btn-container">
                  <BaseButtton 
                     @click="propertyDetails = false;">Back</BaseButtton
                  >
                  <!-- <BaseButtton @click="validateForm2">Save</BaseButtton> -->
                </div>
                
              </div>
              <div class="form" v-if="tab === 2 && propertyDetails && readyEdit && pillsName === 'Overview'">
                <h4 class="mt-3">Update property details</h4>
                <!-- <h6>Please upload all required documents to ensure a smooth and timely verification process. You can skip for now, but note that skipping may delay verification.</h6> -->
                <div class="email-container input-full">
                  <BaseInput
                    type="text"
                    placeholder="Eg: newly built 3 bedroom flat"
                    label="Title"
                    :value="propertyTitle"
                    @input="propertyTitle = $event"
                    :errorMessage="errorMessage.title"
                    @blur="validateTitleInput"
                  />
                </div>

                <!-- <div class="">
                  <label for="propertyType">Property Type</label>
                  <select v-model="propertyType" class="form-control dynamic-input">
                    <option v-for="property in propertyTypes" :key="property.id" :value="property.type">
                      {{ property.type }}
                    </option>
                  </select>
                </div> -->
                  <div class="input-group d-none">
                    <InputSelect
                      v-if="propertyTypes.length > 0 && propertyType" 
                      label="Property Type"
                      @select-option="propertyType = $event"
                      @blur="validatePropertyType"
                      :value="propertyType"
                      :selected="propertyType"
                      :errorMessage="errorMessage.propertyType"
                      :loading="loadingPropertyType"
                    >
                      <option
                        v-for="item in propertyTypes"
                        :key="item.id"
                        :value="item.type"
                       
                        >{{ item.type }}</option
                      >
                    </InputSelect>
                    <InputSelect
                      label="Property subtype"
                      @select-option="propertySubType = $event"
                      @blur="validatePropertySubType"
                      :value="propertySubType"
                      :errorMessage="errorMessage.propertySubType"
                      :loading="loadingPropertySubType"
                    >
                      <option
                        v-for="subType in propertySubTypes"
                        :key="subType.id"
                        :value="subType.subtype"
                        >{{ subType.subtype }}</option
                      >
                    </InputSelect>
                    
                  </div>
                  <!-- <p>{{ propertyTypes }}</p> -->
                  <div class="input-group">
                    <div class="">
                      <label for="propertyType">Property Type</label>
                      <select v-model="propertyType" class="form-control dynamic-input" >
                        <option v-for="property in propertyTypes" :key="property.id" :value="property.type" :disabled="isDisabledOption(property)">
                          {{ property.type }}
                        </option>
                      </select>
                    </div>
                    <!-- <p v-if="propertySubType">subtype{{ propertySubType }}</p> -->
                    <div class="" v-if="propertyType != 'Residential' && propertyType != ''">
                      <label for="propertyType">Property Subtype</label>
                      <select v-model="propertySubType" class="form-control dynamic-input">
                        <option v-for="propertySub in propertySubTypes" :selected="propertySubType" :key="propertySub.id" :value="propertySub.subtype">
                          {{ propertySub.subtype }}
                        </option>
                      </select>
                      <p class="x-error-info" v-if="errorMessage">{{ errorMessage.propertySubType }}</p>
                    </div>
                   
                  </div>
                  
                  <div class="input-group">
                    <BaseInput v-if="propertyType != 'Residential' && propertyType != ''"
                      type="text"
                      placeholder="Property price"
                      label="Price"
                      :value="theRentValueAmount"
                      @input="theRentValueAmount = $event"
                      :errorMessage="errorMessage.genericPrice"
                      @blur="validateGenericPriceInput"
                      />
                  </div>

                  <div class="input-group d-none">
                    <InputSelect
                      label="Business Type"
                      @select-option="typeofbusiness = $event"
                      @blur="validateBusinessType"
                      :value="typeofbusiness"
                      :errorMessage="errorMessage.typeofbusiness"
                    >
                      <option selected disabled>Select</option>
                      <option  v-for="item in businesstypenumber"
                        :key="item.id"
                        :value="item.id">{{ item.businesstype }}</option>
                    </InputSelect>
                  </div>
                  <div class="">
                      <label for="typeofbusiness">Business Type</label>
                      <select v-model="typeofbusiness" class="form-control dynamic-input">
                        <option v-for="item in businesstypenumber" :key="item.id" :value="item.id">
                          {{ item.businesstype }}
                        </option>
                      </select>
                  </div>
                  <div class="input-group d-none">
                    <InputSelect
                      label="State"
                      @select-option="location = $event"
                      @blur="validateLocation"
                      :value="location"
                      :errorMessage="errorMessage.location"
                      :loading="loadingLocation"
                    >
                      <option
                        v-for="state in sortedStates"
                        :key="state.id"
                        :value="state.name"
                        >{{ state.name }}</option
                      >
                    </InputSelect>

                    <InputSelect
                      label="City"
                      @select-option="city = $event"
                      @blur="validateCity"
                      :value="city"
                      :errorMessage="errorMessage.city"
                      :loading="loadingCity"
                    >
                      <option
                        v-for="city in sortedCity"
                        :key="city.id"
                        :value="city.name"
                        >{{ city.name }}</option
                      >
                    </InputSelect> 
                  </div>

                  

                  <!-- <div class="input-group">
                    <BaseInput v-if="propertyType != 'Residential' && propertyType != ''"
                      type="text"
                      placeholder="Property price"
                      label="Price"
                      :value="theRentValueAmount"
                      @input="theRentValueAmount = $event"
                      :errorMessage="errorMessage.genericPrice"
                      @blur="validateGenericPriceInput"
                      />
                  </div> -->

                  <div class="input-group">
                    <div class="">
                      <label for="location">State</label>
                      <select v-model="location" class="form-control dynamic-input">
                        <option v-for="state in sortedStates" :key="state.id" :value="state.name">
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                    <div class="">
                      <label for="city">City</label>
                      <select v-model="city" class="form-control dynamic-input">
                        <option v-for="city in sortedCity" :key="city.id" :value="city.name">
                          {{ city.name }}
                        </option>
                      </select>
                    </div>
                  </div>


                  <div v-if="propertyType != 'Residential' && propertyType != ''">
                    <div class="email-container input-group">
                      <div class="" v-if="propertyType != 'Land'">
                        <label style="color: #57595a; font-size: 14px; " for="status">Bedrooms</label>
                        <select v-model="bedrooms" class="form-control dynamic-input">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                      

                      <div class="" v-if="propertyType != 'Land'">
                        <label style="color: #57595a; font-size: 14px; " for="status">Bathrooms</label>
                        <select v-model="bathrooms" class="form-control dynamic-input">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>

                    </div>
                    <div class="email-container input-group">
                      <div class="" v-if="propertyType != 'Land'">
                        <label style="color: #57595a; font-size: 14px; " for="status">Toilets</label>
                        <select v-model="toilets" class="form-control dynamic-input">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                     

                      <div class="">
                        <label style="color: #57595a; font-size: 14px; " for="status">Status</label>
                        <select v-model="status" class="form-control dynamic-input">
                          <option v-for="status in propertyStatuses" :key="status" :value="status">
                            {{ status }}
                          </option>
                        </select>
                        <p class="x-error-info" v-if="errorMessage">{{ errorMessage.status }}</p>
                      </div>
                    </div>
                  </div>
                  

                  <div class="email-container input-full">
                    <BaseInput
                      type="text"
                      placeholder="Enter property accurate address"
                      label="Property address"
                      :value="propertyAddress"
                      @input="propertyAddress = $event"
                      :errorMessage="errorMessage.propertyAddress"
                      @blur="validatePropertyAddress"
                    />
                  </div>
                  <div class="input-group">
                    <BaseInput
                    type="textarea"
                    placeholder="Provide landmarks or directions, a detailed property description, and any additional remarks."
                    label="Property description"
                    :value="description"
                    @input="description = $event"
                    :errorMessage="errorMessage.description"
                    @blur="validateDescription"
                  />
                  </div>
                  <div v-if="propertyType == 'Residential' && propertyType != ''">
                    <div class="addNewUnit">
                      <div class="unit-text-button">
                        <h5>Units</h5>
                        <button class="btn " @click="addForm()"><span>+</span> Add unit</button>
                      </div>
                      <h6>Add the individual units within this property. Each unit can be tracked and updated separately.</h6>
                    </div>
               
                    <div v-for="(form, index) in forms" :key="index">
                      <div class="unit-count-holder">
                        <h3>Unit {{ index + 1 }}</h3>
                        <div>
                          <!-- <small>Save</small> -->
                          <img v-if="forms.length > 1 && index != 0" @click="removeForm(index)" src="../assets/icons/trash.svg" alt="delete">
                          <button class="btn" :class="{ 'fading-text': updatingUnitLoading && clickedUnitId == form.id }" :disabled="updatingUnitLoading" @click="editPropertySingleUnit(form)">
                            {{ updatingUnitLoading && clickedUnitId == form.id ? 'Editing...': 'Edit'}}
                          </button>
                          <!-- @click="enableItem(index)" -->
                        </div>
                      </div>
          
                      <form @submit.prevent="submitForm(index)">
                        <div class="input-group">
                          <div class="row">
                            <div class="col-md-4">
                              <label for="name">Unit name:</label>
                              <!-- <input class="form-control dynamic-input" id="name" type="text" v-model="form.name" placeholder="Eg. Main" /> -->
                              <select class="form-control dynamic-input" id="unit_name" v-model="form.unit_name">
                                <option  value="" disabled>Select</option>
                                <option
                                v-for="(category, index) in propertyCategory"
                                :key="index"
                                :disabled="isDisabledPropInput(index)"
                                :value="category"
                                >{{ category }}</option
                                >
                      
                          </select>
                            </div>
                            <div class="col-md-4">
                              <label for="price">Unit Amount:</label>
                              <input class="form-control dynamic-input" id="price" type="text" @input="formatPrice(index)" v-model="form.price" placeholder="Enter unit amount" />  
                            </div>
                            <div class="col-md-4">
                              <label for="bedrooms">Number of bedrooms:</label>
                              <select class="form-control dynamic-input" id="bedrooms" v-model="form.bedrooms">
                                <option  value="" selected disabled>Select</option>
                                <option  value="1">1</option>
                                <option value="2">2</option>
                                <option class="3">3</option>
                                <option class="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                            <div class="col-md-4">
                              <label for="bedrooms">Number of bathrooms:</label>
                              <select class="form-control dynamic-input" id="bathrooms" v-model="form.bathrooms">
                                <option  value="" selected disabled>Select</option>
                                <option  value="1">1</option>
                                <option value="2">2</option>
                                <option class="3">3</option>
                                <option class="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                            <div class="col-md-4">
                              <label for="bedrooms">Number of toilets:</label>
                              <select class="form-control dynamic-input" id="toilets" v-model="form.toilets">
                                <option  value="" selected disabled>Select</option>
                                <option  value="1">1</option>
                                <option value="2">2</option>
                                <option class="3">3</option>
                                <option class="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                            <div class="col-md-4">
                              <label for="status">Status:</label>
                              <select class="form-control dynamic-input" name="status" id="status" v-model="form.status">
                                <option value="" disabled>Select status</option>
                                <option
                                  v-for="status in propertyStatuses"
                                  :key="status"
                                  :value="status"
                                  >{{ status }}</option
                                >
                              </select>
                              
                            </div>                          
                          </div>
                        </div>
                        <!-- <div>
                          <label for="name">Name:</label>
                          <input class="form-control dynamic-input" type="text" v-model="form.name" />
                        </div>

                        <div>
                          <label for="email">Email:</label>
                          <input class="form-control dynamic-input" type="email" v-model="form.email" />
                        </div> -->

                        <!-- <button type="submit">Submit Form {{ index + 1 }}</button> -->
                      </form>
                    </div>
                  </div>
                  
                  <div class="green-yellow-btn">
                    <button class="btn save" @click="updatePropertyGenericData()" :disabled="updatingBatch">
                      {{ updatingBatch ? 'Updating...' : 'Update' }}
                    </button>
                    <button class="btn cancel" @click="readyEdit = false;">Cancel</button>
                  </div>
              </div>

              <div class="form" v-if="tab === 2 && pillsName === 'Uploads'">
                <div class="update-top-text" style="display: block;">
                    <h4>Upload property documents</h4>
                    <h6>Please upload all required documents to ensure a smooth and timely verification process. You can skip for now, but note that skipping may delay verification.</h6>
                </div>
                <div class="upload-holder">
                  <div class="holding-upload">
                    <div class="">
                      <h4>Proof of ownership</h4>
                      <h4 v-if="uploadingEvidence" class="uploading-doc">Uploading documents....</h4>
                      <p v-if="!evidenceFile && !property_units[0].evidenceofownership"><img src="../assets/icons/file.svg" alt="file"> No file seletected</p>
                      <p v-if="evidenceFile && !uploadingEvidence || property_units[0].evidenceofownership" class="ellipsis-text" @click="openEvidence(property_units[0].evidenceofownership)" style="cursor: pointer;">
                        <img src="../assets/icons/file.svg" alt="file">
                        {{ evidenceServerFileName ||  property_units[0].evidenceofownership}}
                      </p>
                      <h6>{{property_units[0].evidenceofownership_status}} verification</h6>
                    </div>
                
                    <button class="btn" @click="selectFileEvidenceOfProperty" :disabled="uploadingEvidence">
                      {{ uploadingEvidence ? 'Uploading...' : 'Update file' }}
                    </button>
                    <input @blur="validatePropertyDoc" type="file" ref="propertyEvidence" @change="handleFileUploadEvidence($event)" class="d-none" >
                  </div>
                </div>

                <!-- <div class="upload-holder">
                  <div class="holding-upload">
                    <div class="">
                      <h4>Proof of address</h4>
                        <p>
                          <img src="../assets/icons/file.svg" alt="file">
                          proof of address.docx
                        </p>
                        <h6>Pending verification</h6>
                    </div>
                    <button class="btn">Upload</button>
                  </div>
                </div> -->

                <div class="upload-holder">
                  <div class="holding-upload">
                    <div class="">
                      <h4>Property images</h4>
                      <h4 class="uploading-doc" v-if="uploadingPhoto">Uploading photo....</h4>
                      <p v-if="uploadedPhotos.length == 0 && !uploadingPhoto"><img src="../assets/icons/file.svg" alt="file"> No image seletected</p>
                      <p v-for="(item, index) in uploadedPhotos" :key="index"  class="ellipsis-text" data-toggle="modal" data-target="#image-update">
                        <img src="../assets/icons/file.svg" alt="file">
                        {{item || property_units[0].images}} <span class="d-none" @click.stop="removeUploadedImages(index)">X</span>
                      </p>
                      <!-- <h6>Pending verification</h6> -->
                    </div>
                    <!-- <button class="btn" @click="selectPropertyImage" :disabled="uploadingPhoto">
                      {{ uploadingPhoto ? 'Uploading...' : 'Update file' }}
                    </button> -->
                    <button class="btn"  data-toggle="modal" data-target="#image-update" @click="viewImages()">{{  property_units[0].images.length > 0 ? 'View images' : 'Upload file' }}</button>
                    <input @blur="validatePropertyImage" type="file" ref="propertyImage" @change="handleFileUploadImages($event)" class="d-none" >
                  </div>
                </div>


                <!-- Modal for updating property images starts -->
                <div class="modal fade" id="image-update" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Update image gallery</h5>
                                <button type="button" class="close" @click="selectPropertyImageUpdate" >
                                  <span class="plus" aria-hidden="true">Add New</span>
                                </button>
                            </div>
                           <div class="empty-state" v-if="property_units[0].images.length === 0">
                            <h6>No Property image</h6>
                           </div>
                            <div class="modal-body" v-else>
                                <div class="overlay" v-if="deletingImage">   
                                </div>
                                <form style="position: relative;">
                                    <div class="m-auto text-center mt-4 loadingDiv" v-if="deletingImage">
                                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                    <div class="">
                                      <div class="">
                                            <div class="row">
                                                <div class="col-12 col-md-4 col-sm-6 mb-3 view-image-holder" v-for="(item, index) in property_units[0].images" :key="index">
                                                    <img class="update-image" :src="item" alt="">
                                                    <span v-if="property_units[0].images.length > 0" class="delete-icon" @click="deletePropertyImage(item)"><i class="fa fa-trash"></i></span>
                                                </div>
                                            </div>
                                      </div>
                                    </div>
                                    <p style="font-size: 11px;" class="uploading-doc mt-4" v-if="uploadingPhoto">Uploading photo....</p>
                                    <p style="font-size: 11px;">Delete or add property image to image gallery, click the plus icon to add new image.</p>
                                    <input type="file" ref="propertyUpdateImage" @change="handleUpdateFileUploadImages($event)" class="d-none" >
                                    <div class="mt-4">
                                        <div class="row justify-content-center">
                                            <div class="" v-for="(item, index) in newUploadedPhotos"
                                            :key="index">
                                            <div class="file-display">
                                                <h6>{{item}}<span class="" @click.stop="removeUploadedImages(index)">X</span></h6>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer update-buttons">
                                <button type="button" class="btn cancel  btn-block" data-dismiss="modal">CANCEL</button> 
                                <button type="button" class="btn update btn-block" @click="updateProprtyUnitsImages()" :disabled="uploadingPhoto || propertyUpdating || !this.propertyImageFile">
                                    <span v-if="!propertyUpdating && !uploadingPhoto && !deletingImage">UPDATE</span>
                                    <span class="submitting-text" v-if="propertyUpdating && !uploadingPhoto && !deletingImage">Updating....</span>
                                    <span class="submitting-text" v-if="uploadingPhoto">Uploading image....</span>
                                    <span class="submitting-text" v-if="deletingImage">Deleting image....</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal for updating property images ends -->



                <!-- <div class="btn-container">
                <BaseButtton type="text"
                  >Cancel</BaseButtton
                >
                <BaseButtton @click="validateForm2">Save</BaseButtton>
              </div> -->
              </div>

              <div class="form" v-if="tab === 2 && pillsName === 'Rent info'">
                <div class="rent-info-holder">
                  <div class="update-top-text" style="display: block;">
                    <h4>Rent information</h4>
                    <h6>Check the comprehensive rental details for this property.</h6>
                  </div>

                  <div class="rent-info-wrap">
                    <h4>Rent infomation details</h4>
                    <!-- <div class="row">
                      <div class="col-6 info-rent">
                        <h6 class="mb-0">Unit affected</h6>
                        <select class="form-control info-input" id="units_guarenteed">
                          <option v-if="guaranteedRentProperties.length === 0" value="" selected>No unit affected</option>
                          <option v-for="item in guaranteedRentProperties" :key="item.id">{{ item.nomenclature_serial }}</option>
                        </select>
                      </div>
                      
                    </div> -->
                    
                    
                    <div v-if="visibleInfo.length == 0">
                      <EmptyState :emptyData="emptyDataObject" />
                    </div>                    
                    <div v-else class="info-rent-holder" v-for="(item, index) in visibleInfo" :key="index">
                      <div class="info-list">
                        <div class="icon">
                          <span v-if="item.guaranteed_rent ==='YES'">G</span>
                          <span v-if="item.harmonised_rent ==='YES'">H</span>
                          <span v-if="item.onboarded_date != null">R</span>
                        </div>
                        <div class="info-content">
                          <h3 class="unit-name">{{ item.nomenclature_serial_Number }} <span v-if="index == 0" @click="toggleMessages()">{{!showAllMessages ? 'Open' : 'Close'}}</span></h3>
                          <!-- <p class="info-desc">{{ item.desc }}</p> -->
                          <h6 class="info-date" v-if="item.guranteed_date != null">{{ item.date | moment}}</h6>
                          <h6 class="info-date" v-if="item.harmonized_date != null">{{item.harmonized_date | moment}}</h6>
                          <h6 class="info-date" v-if="item.onboarded_date != null">{{ item.onboarded_date | moment }}</h6>
                          <!-- <span class="info-date">{{ item.date }}</span> -->
                        </div>
                      </div>
                    </div>
                  </div>

                

                  <!-- <div class="rent-info-wrap">
                    <h4>Harmonized  rent</h4>
                    <div class="row">
                      <div class="col-6 info-rent">
                        <h6 class="mb-0">Unit affected</h6>
                        <select class="form-control info-input" id="unit_harmonized">
                          <option v-if="harmonizedRentProperties.length === 0" value="" selected>No unit affected</option>
                          <option v-for="item in harmonizedRentProperties" :key="item.id">{{ item.nomenclature_serial }}</option>
                        </select>
                      </div>
                      
                    </div>
                  </div> -->
                </div> 
              </div>

              <div class="form" v-if="tab === 2 && pillsName === 'Onboarding progress'">
                <div class="onboarding-holder">
                  <div class="update-top-text" style="display: block;">
                    <h4>Onboarding progress</h4>
                    <h6>Track each stage of property and tenant onboarding to stay updated.</h6>
                  </div>
                  <div class="propOnboading">
                    <p>Property onboarding</p>
                    <!-- <small>1/4</small> -->
                  </div>
                </div>

                <div class="onbording-card">
                  <div class="onbording">
                    <div>
                      <h6>Property review</h6>
                      <p v-if="property_units[0].cx_reviewed_date != null">{{property_units[0].cx_reviewed_date | moment}}</p>
                    </div>
                    <div>
                      <h5><span class=""></span> {{property_units[0].onboarding_stage}}</h5>
                    </div>
                  </div>
                  <div class="onbording">
                    <div>
                      <h6>Legal verification</h6>
                      <p v-if="property_units[0].legal_verified_contract_generated_date != null ">{{ property_units[0].legal_verified_contract_generated_date | moment }}</p>
                    </div>
                    <div>
                      <h5><span :class="[property_units[0].legal_verified_contract_generated_date == null ? 'notStarted' : 'completedStatus']"></span>{{ property_units[0].legal_verified_contract_generated_date == null ? 'Not started' : 'Completed' }}</h5>
                    </div>
                  </div>
                  
                </div>

                <div class="propOnboading">
                  <p>Tenant onboarding</p>
                  <!-- <div>fipll</div> -->
                </div>


                <div class="tenant-boarding-holder" v-for="(item, index) in property_units"
                  :key="index">
                  <div class="tenant-boarding-header" @click="showTenantData = index; showTenantInfo = !showTenantInfo">
                    <div>
                      <h6>{{ item.tenant_onboarding.tenant_agreement.tenant }}</h6>
                      <p>{{ item.nomenclature_serial }}</p>
                    </div>
                    <div>
                      <div class="percentage-holder">
                        <div class="progress-bar">
                          <div class="progress-fill" :style="{width: `${item.tenant_onboarding.percentage_onboarding}%`}"></div>
                        </div>
                        <span>{{item.tenant_onboarding.percentage_onboarding}}%</span>
                      </div>
                      <i class="fa d-flex justify-content-end" :class="[showTenantData == index && showTenantInfo ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                    </div>
                  </div>
                  <div v-if="showTenantData == index && showTenantInfo">
                    <div class="onbording">
                      <div>
                        <h6>Tenant documentation</h6>
                        <p v-if="item.tenant_onboarding.tenant_documentation.date != null">{{ item.tenant_onboarding.tenant_documentation.date  | moment}}</p>
                      </div>
                      <div>
                        <h5><span class="completedStatus" :class="[item.tenant_onboarding.tenant_documentation.status === 'in_progress' ? 'inProgress' : '', item.tenant_onboarding.tenant_documentation.status === 'completed' ? 'completedStatus' : '', 'notStarted']"></span>{{ item.tenant_onboarding.tenant_documentation.status }}</h5>
                      </div>
                    </div>

                    <div class="onbording bordingList">
                      <div>
                        <h6>Tenant agreement</h6>
                        <p v-if="item.tenant_onboarding.tenant_agreement.date != null">{{ item.tenant_onboarding.tenant_agreement.date  | moment}}</p>
                      </div>
                      <div>
                        <h5><span class="" :class="[item.tenant_onboarding.tenant_agreement.status === 'in_progress' ? 'inProgress' : '', item.tenant_onboarding.tenant_agreement.status === 'completed' ? 'completedStatus' : '', 'notStarted']"></span> {{  item.tenant_onboarding.tenant_agreement.status }}</h5>
                      </div>
                    </div>
                    <div class="onbording bordingList">
                      <div>
                        <h6>Tenant guarantor</h6>
                        <!-- <p>{{ item.tenant_onboarding.tenant_agreement.date  | moment}}</p> -->
                      </div>
                      <div>
                        <h5><span class="" :class="[item.tenant_onboarding.tenant_documentation.tenant_guarantor == null ? 'notStarted' : 'completedStatus']"></span> {{  item.tenant_onboarding.tenant_documentation.tenant_guarantor == null ? 'Not started' : 'Completed' }}</h5>
                      </div>
                    </div>
                  </div>
                  
                </div>
                
              </div>
            </div>
        </div>
    </div>
</template>


<script>
// import CF from "../helpers/currencyFormatter";
// import Loader from './Loader';
import properties from "../services/properties";
import BaseButtton from "../components/UI/BaseButtton.vue";
import BaseInput from "../components/form/BaseInput.vue";
import InputSelect from "../components/form/InputSelect.vue";
import Info from "../components/Layout/Info.vue"
import directory from "../services/directory";
import Loader from './Loader';
import moment from 'moment';
import EmptyState from "./EmptyState.vue";

// import $ from "jquery";
export default {
  name: "LandlordPropertyViewEdit",
  components: {
    // Loader,
    BaseInput,
    BaseButtton,
    InputSelect,
    Info,
    Loader,
    EmptyState,
  },
  data() {
    return {
      loading: false,
      invalidFormArray: false,
      readyEdit: false,
      updatingLandlordLoading: false,
      updatingBatch: false,
      uploadingPhoto: false,
      loadingPropertyType: false,
      updatingUnitLoading: false,
      loadingPropertySubType: false,
      loadingCity: false,
      loadingLocation: false,
      uploadingEvidence: false,
      showTenantData: '',
      showTenantInfo: false,
      deletingImage: false,
      propertyUpdating: false,
      showAllMessages: false,
      // editFileReady: false,
      // isDisabledPropInput: false,
      enabledIndex: null,
      landlordInfo: '',
      landlordPropertyLists: [],
      propertyTypes: [],
      propertyUnitList: [],
      propertyTitle: "",
      propertySubTypes: [],
      propertyCategory: [],
      propertySubType: "",
      squareFootage: "",
      description: "",
      propertyValue: "",
      propertyType: "",
      selectedType:"",
      noOfBedroom: "",
      noOfBathroom: "",
      noOfToilets: "",
      bedrooms: "",
      bathrooms: "",
      toilets: "",
      status: "",
      states: [],
      city: "",
      cities: [],
      propertyId: '',
      businesstypenumber: '',
      propertyStatuses: [],
      userID: '',
      tab: 1,
      landlordDetails: 'view',
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      typeofbusiness: "",
      location: "",
      propertyAddress: "",
      evidenceFile: "",
      propertyImageFile: "",
      evidenceServerFileName: "",
      singlePropertyDetails: {},
      landlordIdFile: "",
      landlordID: "",
      genericPrice: "",
      errorMessage: {
        title: "",
        propertyTitle: "",
        noOfBedroom: "",
        noOfBathroom: "",
        noOfToilets: "",
        bedrooms: "",
        bathrooms: "",
        toilets: "",
        status: "",
        propertyType: "",
        propertySubType: "",
        squareFootage: "",
        description: "",
        // leaseOrRent: "",
        typeofbusiness: "",
        propertyValue: "",
        form: "",
        firstName: "",
        propertyAddress: "",
        lastName: "",
        email: "",
        phone: "",
        amount: "",
        amount2: "",
        address: "",
        genericPrice: "",
        age: "",
        gender: "",
        location: "",
        city: "",
        clickedUnitId: "",
        evidenceServerFileName: "",
        propertyImageFile: "",
        evidenceFile: "",
        uploadedPhotos: [],
        newUploadedPhotos: [],
      },
      uploadedPhotos: [],
      newUploadedPhotos: [],
      propertyDetails: false,
      pillsName: 'Overview',
      pills: [
        {
          name: 'Overview'
        },
        {
          name: 'Uploads'
        }
        ,
        {
          name: 'Rent info'
        }
        ,
        {
          name: 'Onboarding progress'
        }
      ],
      forms: [
        {
          unit_name: '',
          price: '',
          bedrooms: '',
          bathrooms: '',
          toilets: '',
          status: '',
        },
      ],
      duplicateFormNumber: 0,
      // tenantsOboarding: [
      //   {
      //     name: 'Olivia Jackson Micheal',
      //     suit: 'Suit A',
      //     percentage: '70',
      //     id: 1
      //   },
      //   {
      //     name: 'Martins Salvador Okon',
      //     suit: 'Suit B',
      //     percentage: '40',
      //     id: 2
      //   }
      // ],
      emptyDataObject: {
        header: 'No rent Info',
        option: 'When there is rent info. it will be updated.'
      },
      mess: [
        {
          title: "welcom my guy",
          desc: "Another day to try",
          date: "12/23/2025"
        },
        {
          title: "welcom my guy2",
          desc: "Another day to try",
          date: "12/23/2025"
        },
        {
          title: "welcom my guy3",
          desc: "Another day to try",
          date: "12/23/2025"
        },
        {
          title: "welcom my guy4",
          desc: "Another day to try",
          date: "12/23/2025"
        }

      ],
      guaranteedRentProperties: [],
      harmonizedRentProperties: [],
      regularizedRentProperties: [],
      randomizeRentInfoArray: [],
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;

  },
  mounted() {
    this.getPropertyStatuses();
    this.getPropCategory();
    this.fetchLandlordDetailsProperties();
    this.getPropertyTypes();

    this.getStates();
    this.getBusinessTypes();
  
  },
  beforeDestroy() {
  },
  methods: {
    addUnitToProperty(batch) {
      this.$router.push(`/user/add-unit/${batch}`);
    },
    // backToLandlordList() {
    //   this.$router.push(`/user/add-unit`);
    // },
    isDisabledOption(option) {
      if (this.propertyType == 'Residential') {
        // If the selected option is 'Residential', disable all other options
        return option.type != 'Residential';
      } else {
        // Otherwise, disable only the option with value 'Residential'
        return option.type == 'Residential';
      }
    },
    addNewProperty() {
      const id = this.$route.params.id;
      this.$router.push(`/user/addpropertytolandlord/${id}`);
      // skip = false; tab = 2; propertyDetails = false;  readyEdit = false; pillsName = 'Overview';
    },
    toggleMessages() {
      this.showAllMessages = !this.showAllMessages;
    },
    async fetchLandlordDetailsProperties() {
      this.loading = true;
      const id = this.$route.params.id;
      await directory.landlordProperties(id).then(data => {
        // console.log("Landlord properties:", data);
        this.loading = false;
        if (data.success) {
            this.landlordInfo =  data.landlord_info;
            this.landlordPropertyLists = data.properties;
            this.firstName = this.landlordInfo.firstname;
            this.lastName = this.landlordInfo.lastname;
            this.email = this.landlordInfo.email;
            this.phone = this.landlordInfo.phone;
            this.address = this.landlordInfo.address;
            this.landlordID = this.landlordInfo.meansofidfileurl;
        } else {
            this.$toast.error(data.error);
        }
      }).catch(() => {
          this.loading = false;
          this.$toast.error("please check your network and refresh the page");
      });
    },
    selectLandlordId() {
      this.$refs.landlordIdRef.click();
    },
    handleLandlordId(event) {
        this.landlordIdFile = event.target.files[0];
        // const file = event.target.files[0];
        // // Handle the uploaded file here
        // this.landlordIdFile = event.target.files[0];
        // const file = event.target.files[0];
        if (
            // this.evidenceFile.type != "application/pdf"
            this.landlordIdFile.type === "image/jpeg" ||
            this.landlordIdFile.type === "image/jpg" ||
            this.landlordIdFile.type === "image/png" ||
            this.landlordIdFile.type === "image/gif" ||
            // this.propertyImageFile.type === "application/pdf" ||
            this.landlordIdFile.type === "svg+xml"
        ) {
            this.uploadingPhoto = true;
        } else {
            this.uploadingPhoto = false;
            this.landlordIdFile = "";
            return this.$toast.error("select a file");
        }
        
        // Handle the uploaded file here
        const sizeInMb = this.landlordIdFile.size / (1024 * 1024).toFixed(3);
        if (sizeInMb > 5) {
            this.uploadingPhoto = false;
            return this.$toast.error("File size more than 5MB");
        }
        let formData = new FormData();
        formData.append("file", this.landlordIdFile);
    },
    updateLandlordDetails() {
      this.updatingLandlordLoading = true;
      let formData = new FormData();
        formData.append("file", this.landlordIdFile);
        formData.append("address", this.address);
        formData.append("lastname", this.lastName);
        formData.append("firstname", this.firstName);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("landlord_id", this.$route.params.id);
      // const body = {
      //   address: this.address,
      //   lastname: this.lastName,
      //   firstname: this.firstName,
      //   email: this.email,
      //   phone: this.phone,
      //   landlord_id: this.$route.params.id,
        
      // };
      properties.updateLandlordDetailsFormData(formData)
        .then((res) => {
          this.updatingLandlordLoading = false;
          this.loadingL = false;
          if (res.success) {  
            this.landlordDetails = 'view'; 
            this.pillsName = 'Overview';
            this.$toast.success("Landlord details updated");
            this.fetchLandlordDetailsProperties();
          } else {
            this.$toast.error("ERROR");
          }
        })
        .catch((err) => {
          console.log(err);
          this.updatingLandlordLoading = false;
        });
    },
    formatPrice(index) {
      // Remove existing commas
      // const unformatedPrice = this.forms[index].price.replace(/,/g, "");
      // // Add commas back in
      // this.forms[index].price = new Intl.NumberFormat().format(unformatedPrice);

      let value = this.forms[index].price.replace(/,/g, ""); // Remove existing commas
      if (!isNaN(value)) {
        this.forms[index].price = new Intl.NumberFormat().format(value); // Format with commas
      } else {
        this.forms[index].price = ""; // Reset invalid input
      }
    },
    formatAllPrices() {
      this.forms = this.forms.map((item) => ({
        ...item,
        price: new Intl.NumberFormat().format(item.price.replace(/,/g, "")),
      }));
    },
    addForm() {   

      const lastForm = this.forms[this.forms.length - 1]; // Get the last form
      // Validate if all fields of the last form are filled
      if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        this.invalidFormArray = true;
        return;
      }
      
      // Add a new form if validation passes
      this.forms.push({ unit_name: '', price: '', bedrooms: '',  bathrooms: '', toilets: '', status: ''});
    },
    removeForm(index) {
      this.forms.splice(index, 1); // Remove 1 element at the given index
    },
    viewImages() {
      // console.log("Uploaded image", this.uploadedPhotos);
    },
    addLandlord() {
      this.$router.push("/user/register-landlord");
    },
    triggerEditProperty() {
      this.readyEdit = true;
 
 
    },
    enableItem(index) {
      this.enabledIndex = index; // Set the clicked item's index as enabled
    },
    isDisabledPropInput(index) {
      // Disable all items except for the one with enabledIndex
      return this.enabledIndex !== index && this.enabledIndex !== null;
    },
    
    editPropertySingleUnit(item) {
      this.updatingUnitLoading = true;
      this.clickedUnitId = item.id;
      // const updatedArray = this.forms.map((item) => ({
      //   ...item,
      //   price: item.price.replace(/,/g, ""),
      // }));
      const price = item.price.replace(/,/g, "");
      const body = {
        propertyid: this.propertyId,
        unit_name: item.unit_name,
        bedrooms: item.bedrooms,
        bathrooms: item.bathrooms,
        price: price,
        status: item.status,
        toilet: item.toilet,
      }
      properties.updateSingleUnit(JSON.stringify(body))
        .then((res) => {
          this.updatingUnitLoading = false;
          if (res.success) {
            (this.errorMessage.form = ""), (this.formSubmitted = true);
            // this.clearForm();
            this.$toast.success("SUCCESS, PROPERTY UNIT UPDATED");
            this.readyEdit = true;
            this.fetchLandlordDetailsProperties();
            // this.$router.push(`/user/landlords`);
            // reset form step
          } else {
            this.$toast.error("ERROR");
          }
        })
        .catch((err) => {
          console.log(err);
          this.updatingUnitLoading = false;
        }
      );
    },
    viewLandlordDetails(property) {
      // console.log("PropertyListClicked", property)
      this.singlePropertyDetails = property;
      this.property_units = property.property_units;
      this.propertyDetails = true;
      this.uploadedPhotos = property.property_units[0].images;
      this.genericPrice = property.price;
      this.propertyTitle = property.title;
      this.bedrooms = property.bedrooms;
      this.bathrooms = property.bathrooms;
      this.toilets = property.toilets;
      this.status = property.status;
      if (this.propertyType == 'Residential') {
        console.log("residential")
        this.propertyType = property.property_units[0].typename;
        this.propertySubType = property.property_units[0].subtypename;
      } else {
        // this.propertyType = property.property_units[0].typename;
        // this.propertySubType = property.property_units[0].subtypename;
        this.propertyType = property.property_units[0].typename;
        // this.propertySubType = property.subtypename;
        this.propertySubType = property.property_units[0].subtypename;
        // console.log("non-residential",this. propertySubType)
      }

      // this.propertyType = property.typename;
      // this.propertySubType = property.subtypename;
      
      
      this.propertyAddress = property.address;
      this.description = property.property_units[0].description;
      this.location = property.property_units[0].state;
      this.city = property.property_units[0].city;
      this.typeofbusiness = (property.property_units[0].businesstype).toString();
      // this.selectedType = property.property_units[0].typename;
      this.property_units.forEach(item => {
        item.unit_name = item.nomenclature; // Add new key to each item
        item.nomenclature_serial_Number = item.nomenclature_serial;
      });

      this.guaranteedRentProperties = this.property_units.filter(property => 
        property.guaranteed_rent === 'YES' 
      );
      this.harmonizedRentProperties = this.property_units.filter(property => 
        property.harmonised_rent === 'YES'
      );
      this.regularizedRentProperties = this.property_units.filter(property => 
        property.onboarded_date != null
      );

      const rentInfoArray = [...this.guaranteedRentProperties, ...this.harmonizedRentProperties]
      this.randomizeRentInfoArray = rentInfoArray.sort(() => Math.random() - 0.5);


      // Selecting the specific keys i want to use instead of passing all the property data
      const selectedKeysArray = this.property_units.map(item => 
        Object.fromEntries(Object.entries(item).filter(([key]) => ['unit_name', 'nomenclature_serial', 'price', 'bedrooms', 'bathrooms', 'toilets', 'status', 'id', 'images', 'evidenceofownership', 'evidenceofownership_status', 'tenant_onboarding'].includes(key)))
      );
      this.propertyId = selectedKeysArray[0].id;
      this.forms = selectedKeysArray;
      
    },
    duplicateFormData() {
      const duplicatedArray = this.forms.flatMap(item => Array(Number(`${this.duplicateFormNumber}`)).fill({ ...item }));
      this.forms = duplicatedArray;
    }, 
    isForm2Valid() {
      return (
        this.validateLocation() && 
        this.validateCity() && 
        this.validateDescription() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() && 
        this.validateTitleInput() && 
        this.validatePropertyAddress() &&
        this.validateBusinessType() &&

        this.validateGenericPriceInput() &&
        this.validateStatus() 
        // this.validateBedrooms() &&
        // this.validateBathrooms() && 
        // this.validateToilets()
        // this.validateSquareFootage() &&
      );
    }, 
    updatePropertyGenericData() {
      this.updatingBatch = true;
      const lastForm = this.forms[this.forms.length - 1];
      // if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
      //   this.updatingBatch = false;
      //   return this.$toast.error("Fill all property unit details");
      // }
      if (this.propertyType == 'Residential' ) {
        if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status )
        this.updatingBatch = false;
        return this.$toast.error("Fill all unit forms");
      }
      if (!this.isForm2Valid() && this.propertyType != 'Residential') {
        this.updatingBatch = false;
        return this.$toast.error("Fill all required fileds");
      }
      const updatedArray = this.forms.map((item) => ({
        ...item,
        price: item.price.replace(/,/g, ""),
      }));
    
      let isSubType = null; 
      if (this.propertyType != 'Residential' && this.genericPrice != '') {
        isSubType = this.propertySubTypeId;
        this.genericPrice = this.theRentValueAmount.replace(/,/g, "");
        
      }

      let url;
      let payload;
      if (this.propertyType == 'Residential') {
        url = 'edit-property-in-batch'
      }
      if ((this.propertyType == 'Commercial') || (this.propertyType == 'Industrial') || (this.propertyType == 'Land')) {
        url = 'smart_edit_commercial_and_industrial'
      }

      const body1 = {
        // userId: this.userID,
        // facilities: this.facilities,
        subtype: this.propertySubTypeId,
        type: this.propertyTypeId,
        cityid: this.cityId,
        stateid: this.stateId,
        address: this.propertyAddress,
        businesstype: Number(this.typeofbusiness),
        description: this.description,
        title: this.propertyTitle,
        batch: this.singlePropertyDetails.batch,
        landlord_id: this.$route.params.id,

        price: this.genericPrice,
        bedrooms: this.bedrooms,
        bathrooms: this.bathrooms,
        status: this.status,
        toilets: this.toilets,
      }
      const body = {
        // userId: this.userID,
        // facilities: this.facilities,
        subtype: isSubType,
        type: this.propertyTypeId,
        cityid: this.cityId,
        stateid: this.stateId,
        address: this.propertyAddress,
        businesstype: Number(this.typeofbusiness),
        description: this.description,
        title: this.propertyTitle,
        batch: this.singlePropertyDetails.batch,
        units_objects: updatedArray,
        landlord_id: this.$route.params.id,
      }

      if (this.propertyType == 'Residential') {
        payload = body;
      }
      if ((this.propertyType == 'Commercial') || (this.propertyType == 'Industrial') || (this.propertyType == 'Land')) {
        payload = body1;
      }

     
      properties.updateGeneralPropertyDetails(JSON.stringify(payload), url)
        .then((res) => {
          this.updatingBatch = false;
          if (res.success) {
            (this.errorMessage.form = ""), (this.formSubmitted = true);
            // this.clearForm();
            this.$toast.success("SUCCESS, PROPERTY GENERAL DETAILS UPDATED");
            this.readyEdit = true;
            this.fetchLandlordDetailsProperties();
            // this.$router.push(`/user/landlords`);
            // reset form step
          } else {
            this.$toast.error("ERROR", res.error);
          }
        })
        .catch((err) => {
          console.log(err);
          this.updatingBatch = false;
          this.$toast.error(err);
        }
      );
    },
    selectPropertyImage() {
      this.$refs.propertyImage.click();
    },
    selectPropertyImageUpdate() {
      this.$refs.propertyUpdateImage.click();
    },
    removeUploadedImages(index) {
        this.uploadedPhotos.splice(index, 1);
    },
    openEvidence(url) {
      window.open(url, '_blank');
    },  
    updateProprtyUnitsImages() {
      this.propertyUpdating = true;
      let data = {
        batch: this.singlePropertyDetails.batch,
        images: this.newUploadedPhotos,
      }
      data = JSON.stringify(data);
      properties.updatingPropertyImages(data).then((res) => {
          // console.log("deleted image", res);
          this.propertyUpdating = false;
          if (res.success) {
              this.fetchLandlordDetailsProperties();
              this.$toast.success("Image updated");
          }
      }).catch((err) => {
          this.propertyUpdating = false;
        this.$toast.error("Error occurred");
        console.log(err);
      });
    },
    handleUpdateFileUploadImages(event) {
        this.propertyImageFile = event.target.files[0];
        const file = event.target.files[0];
        // Handle the uploaded file here
        this.propertyImageFile = event.target.files[0];
        // const file = event.target.files[0];
        this.uploadingPhoto = true;
        if (this.uploadedPhotos.length == 0) {
            this.propertyFirstImage = URL.createObjectURL(file);
        }
        

        if (!this.propertyImageFile) {
            this.uploadingPhoto = false;
            // this.$toast.success("success");
            return this.$toast.error("select a file");
        }
        if (
            // this.evidenceFile.type != "application/pdf"
            this.propertyImageFile.type === "image/jpeg" ||
            this.propertyImageFile.type === "image/jpg" ||
            this.propertyImageFile.type === "image/png" ||
            this.propertyImageFile.type === "image/gif" ||
            // this.propertyImageFile.type === "application/pdf" ||
            this.propertyImageFile.type === "svg+xml"
        ) {
            this.uploadingPhoto = true;
        } else {
            this.uploadingPhoto = false;
            this.propertyImageFile = "";
            return this.$toast.error("select a IMAGE file");
        }
        
        // Handle the uploaded file here
        const sizeInMb = this.propertyImageFile.size / (1024 * 1024).toFixed(3);
        if (sizeInMb > 5) {
            this.uploadingPhoto = false;
            return this.$toast.error("File size more than 5MB");
        }
        let formData = new FormData();
        formData.append("file", this.propertyImageFile);
        formData.append("documentType", this.propertyImageFile.name);
        properties.uploadPropertyimages(formData)
        .then((res) => {
            this.uploadingPhoto = false;
            if (res.success) {
                JSON.stringify(this.newUploadedPhotos.push(res.filename));
                // window.localStorage.setItem("__uploadedImages", JSON.stringify(this.uploadedPhotos))
                this.$toast.success("SUCCESS");
            } else {
              this.$toast.error("ERROR");
            }
        })
        .catch((err) => {
            console.log(err);
            this.uploadingPhoto = false;
        });

    },

    deletePropertyImage(url) {
        this.deletingImage = true;
        let data = {
            id: this.propertyId,
            url: url,
        }
        data = JSON.stringify(data);
        properties.agentDeleteSinglePropertyImage(data).then((res) => {
            // console.log("deleted image", res);
            this.deletingImage = false;
            if (res.success) {
                this.fetchLandlordDetailsProperties();
                this.$toast.success("Image removed");
            }
        }).catch((err) => {
            this.deletingImage = false;
          this.$toast.error("Error occurred");
          console.log(err);
        });
    },
    validatePropertyImage() {
      if (this.uploadedPhotos.length == 0) {
        this.errorMessage.propertyImageFile = "Property image(s) required!";
        return false;
      }
      this.errorMessage.propertyImageFile = "";
      return true;
    },
    handleFileUploadImages(event) {
      this.uploadingPhoto = true;
      this.propertyImageFile = event.target.files[0];
      const file = event.target.files[0];
      // Handle the uploaded file here
      this.propertyImageFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingPhoto = true;
      if (this.uploadedPhotos.length == 0) {
        this.propertyFirstImage = URL.createObjectURL(file);
      }
     

      if (!this.propertyImageFile) {
        this.uploadingPhoto = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          // this.evidenceFile.type != "application/pdf"
          this.propertyImageFile.type === "image/jpeg" ||
          this.propertyImageFile.type === "image/jpg" ||
          this.propertyImageFile.type === "image/png" ||
          this.propertyImageFile.type === "image/gif" ||
          // this.propertyImageFile.type === "application/pdf" ||
          this.propertyImageFile.type === "svg+xml"
      ) {
        this.uploadingPhoto = true;
      } else {
        this.uploadingPhoto = false;
        this.propertyImageFile = "";
        return this.$toast.error("select a IMAGE file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.propertyImageFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingPhoto = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.propertyImageFile);
      formData.append("documentType", this.propertyImageFile.name);
      properties.uploadPropertyimages(formData)
      .then((res) => {
        this.uploadingPhoto = false;
        if (res.success) {
          JSON.stringify(this.uploadedPhotos.push(res.filename));
          window.localStorage.setItem("__uploadedImages", JSON.stringify(this.uploadedPhotos))
          // console.log("Uploaded file", this.uploadedPhotos);
          this.$toast.success("SUCCESS");
        } else {
          this.$toast.error("ERROR");
        }
      })
      .catch((err) => {
        console.log(err);
        this.uploadingPhoto = false;
      });

    },
    selectFileEvidenceOfProperty() {
      this.$refs.propertyEvidence.click();
    },
    validatePropertyDoc() {
      if (this.evidenceFile === '') {
        this.errorMessage.evidenceFile = "Property document required!";
        return false;
      }
      this.errorMessage.evidenceFile = "";
      return true;
    },
    handleFileUploadEvidence(event) {
      this.uploadingEvidence = true;
      this.evidenceFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingEvidence = true;
      this.evidenceFileName = this.evidenceFile.name;

      if (!this.evidenceFile) {
        this.uploadingEvidence = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          this.evidenceFile.type === "application/pdf"
          // this.evidenceFile.type === "image/jpeg" ||
          // this.evidenceFile.type === "image/jpg" ||
          // this.evidenceFile.type === "image/png" ||
          // this.evidenceFile.type === "image/gif" ||
          // this.evidenceFile.type === "application/pdf" ||
          // this.evidenceFile.type === "svg+xml"
      ) {
        this.uploadingEvidence = true;
      } else {
        this.uploadingEvidence = false;
        this.evidenceFile = "";
        return this.$toast.error("select a PDF file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.evidenceFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingEvidence = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.evidenceFile);
      formData.append("batch", this.singlePropertyDetails.batch);
      formData.append("documentType", this.evidenceFile.name);
      properties.uploadPropertyEvidenceOfOwenerUpdate(formData)
      .then((res) => {
        this.uploadingEvidence = false;
        // console.log("evidence File", res);
        if (res.success) {
            this.evidenceServerFileName = res.filename;
            this.fetchLandlordDetailsProperties();
          this.$toast.success("SUCCESS");
        } else {
          this.$toast.error("ERROR");
        }
      })
      .catch((err) => {
        console.log(err);
        // this.editFileReady = false;
        this.uploadingEvidence = false;
      });

    },
    // updateProprtyUnitsEvidenceOfOwnership() {
    //   this.uploadingEvidence = true;
    //   let data = {
    //     batch: this.singlePropertyDetails.batch,
    //     file: this.evidenceServerFileName,
    //   }
    //   data = JSON.stringify(data);
    //   properties.updatingPropertyOwnwershipDoc(data).then((res) => {
    //       // console.log("deleted image", res);
    //       this.uploadingEvidence = false;
    //       if (res.success) {
    //           this.fetchLandlordDetailsProperties();
    //           this.$toast.success("Document updated");
    //       }
    //   }).catch((err) => {
    //       this.uploadingEvidence = false;
    //     this.$toast.error("Error occurred");
    //     console.log(err);
    //   });
    // },
    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " Please input landlord's first name";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },

    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Please input landlord's last name";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (
        isNaN(this.phone.trim()) ||
        this.phone.trim().length < 11 ||
        Number(this.phone.trim()) < 0
      ) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    validateLandlordAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " Please input landlord's address";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },
    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },
    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "" || this.propertySubType === null && this.propertyType != 'Residential') {
        this.errorMessage.propertySubType = "Please select property subtype";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    validateGenericPriceInput() {
      if (this.genericPrice == "" || this.genericPrice == null || this.genericPrice == 0 && this.propertyType != 'Residential') {
        this.errorMessage.genericPrice = "Please select price";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    validateStatus() {
      if (this.status.trim() === "" && this.propertyType != 'Residential') {
        this.errorMessage.status = " Property status required!";
        return false;
      }
      this.errorMessage.status = "";
      return true;
    },
    validatePropertyAddress() {
      if (this.propertyAddress.trim() === "") {
        this.errorMessage.propertyAddress = " Please input property address";
        return false;
      }
      this.errorMessage.propertyAddress = "";
      return true;
    },
    validateDescription() {
      if (this.description.trim() === "") {
        this.errorMessage.description = " Property description required!";
        return false;
      }
      this.errorMessage.description = "";
      return true;
    },
    
    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateCity() {
      if (this.city === "") {
        this.errorMessage.city = "Please select city";
        return false;
      }

      this.errorMessage.city = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;
          // this.getCities();
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    

    getCities(id) {
      this.loadingCity = true;
      properties
        .fetchCities(id)
        .then((data) => {
          this.loadingCity = false;

          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPropCategory() {
      this.loadingLocation = true;
      properties
        .fetchPropCategory()
        .then((data) => {
          if (data.success) {
            // this.propertyCategory = data.categories;
            this.propertyCategory = data.updated_category;
          }
          // this.loadingLocation = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
   
  },
  computed: {
    // formatAllPrices() {
    //   this.forms = this.forms.map((item) => ({
    //     ...item,
    //     price: new Intl.NumberFormat().format(item.price.replace(/,/g, "")),
    //   }));
      
    // },
    visibleInfo() {
      // Show all messages if toggled, otherwise only the first message
      return this.showAllMessages ? this.randomizeRentInfoArray : this.randomizeRentInfoArray.slice(0, 1);
    },
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    sortedCity() {
      return [...this.cities].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },
    cityId() {
      return this.cities.find((el) => el.name === this.city).id || "";
    },
    sForm1Valid() {
      return (
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validatePhone() &&
        this.validateEmail() &&
        this.validateLandlordAddress() 
      );
    },
    theRentValueAmount: {
      get() {
        return this.genericPrice;
      },
      set(value) {
        let amountt = value.split(",").join("");
        // this.amount2 = (amountt);
        let decimalArray = amountt.split(".");
        if (decimalArray.length === 2) {
          amountt = decimalArray[0];
          amountt = (+amountt).toLocaleString();
          this.genericPrice = amountt + "." + decimalArray[1];
        } else {
          this.genericPrice = (+amountt).toLocaleString();
          // const xx = this.genericPrice.replace(/[^0-9.]/g, "");
          // console.log("xx", xx);
        }
      },
    },
    // status2: {
    //   get() {
    //     return this.status;
    //   },
    //   set(value) {
    //     this.status = value;
    //   }
    // }
  },
  watch: { 

    // theRentValueAmount(newVal) {
    //   if (newVal == '') {
    //    this.validateGenericPriceInput();
    
    //   }
    // },
    readyEdit(newVal) {
      if (newVal) {
        // Wait for the DOM to update before formatting prices
        this.$nextTick(() => {
          this.formatAllPrices();
        });
      }
    },
    duplicateFormNumber(newData) {
      if (newData === '') {
        // this.duplicateFormNumber = 0;
        return this.duplicateFormNumber = ''
      }
      if (newData) {
        this.duplicateFormData();
      }
    },
    propertyType(newType) {
       if (newType) {
        this.subtypeId = this.propertyTypes.find((el) => el.type === newType)["id"];
        // this.isDisabledOption();
       }

      if (this.subtypeId) {
          this.getPropertySubTypes(this.subtypeId);
      } else {
        this.propertySubTypes = [];
      }
      // if(newType && this.propertyType != 'Residential') {
      //   this.propertySubType = '';
      //   this.forms = [
      //     {
      //       unit_name: '',
      //       price: '',
      //       bedrooms: '',
      //       bathrooms: '',
      //       toilets: '',
      //       status: '',
      //     },
      //   ];
      // }
      // else {
      //   this.genericPrice = '';
      //   this.bedrooms = '';
      //   this.bathrooms = '';
      //   this.toilets = "";
      // }
    },
    
    location(newState) {
       if (newState) {
        this.stateIdd = this.states.find((el) => el.name === newState)["id"];
       }

        if (this.stateIdd) {
          this.getCities(this.stateIdd);
        } else {
          this.cities = [];
        }
    },
    

  },
  filters: {
    moment: function(date) {
      return moment(date).format('MMMM Do YYYY');
    }
    // moment: function(date) {
    //   return moment(date).format('MMMM DD YYYY, h:mm:ss a');
    // }
  },
  immediate: true
};
</script>



<style scoped lang="scss">

.fading-text {
    animation: fade-blink 2s infinite;
  }

@keyframes fade-blink {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully invisible */
  }
}
.holder {
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
}

.tenant-boarding-holder {
  border: 1px solid #E2E8F0;
  border-radius: 10px;
}
.tenant-boarding-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 15px;
  background: #F5F6FA;
  cursor: pointer;
}

.completedStatus, .inProgress, .notStarted {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.completedStatus {
  background: #2BC300;
}
.inProgress {
  background: #FFB100;
}
.notStarted {
  background: #D9D9D9;
}
.onbording-card {
  border: 1px solid #E2E8F0;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.onbording {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2E8F0;
  padding-top: 10px;
  padding-bottom: 10px;
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
    padding-left: 15px;
  }
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    padding-left: 15px;
  }
  h5 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #555C74;
    padding-right: 15px;
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
  } 
}
.onbording:last-child {
  border-bottom: none;
  padding-bottom: 0;
  // padding-top: 0;
}
.bordingList:last-child {
  padding-bottom: 10px !important;
}
.propOnboading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // margin-bottom: 10px;
  p {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  small {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #AAADBA;
  }
}
.upload-holder {
  border: 1px solid #E4E7EC;
  padding: 15px;
  border-radius: 6px;
  // margin-bottom: 10px;
}

.rent-info-wrap {
  border: 1px solid #E2E8F0;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
    margin-bottom: 15px;
  }
}
.info-input {
  border: none;
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #2B3352;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
}
.info-rent {
  margin-bottom: 20px;
  h6, h5 {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  h6 {
    font-weight: 400;
    color: #555C74;
  }
  h5 {
    font-weight: 600;
    color: #2B3352;
  }
}

.holding-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    border: 1px solid #555C74;
    color: #555C74;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #2B3352;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    padding: 5px;
  }
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
}

.x-error-info {
  color: rgb(234, 0, 0);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
}

.property-details-view-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 15px;
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #555C74;
    margin-bottom: 20px;
  }
  h6, h5 {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  h6 {
    font-weight: 400;
    color: #555C74;
  }
  h5 {
    font-weight: 500;
    color: #2B3352;
  }
}

.border-line-bottom {
  border-bottom: 1px solid #E2E8F0;
  margin-bottom: 15px;
  margin-top: 15px;
}
.border-line-bottom:last-child {
  border-bottom: none;
  margin-bottom: 0;
  margin-top: 0;
}

.detail-tabs-holder {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 25px;
  
}

.detail-tabs {
  background: #F5F6FA;
  border-radius: 50px;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding: 10px 10px;
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #555C74;
    padding: 10px 20px;
    outline: 0;
    box-shadow: none;
    border-radius: 50px;
    // margin: 0 5px;
    // width: 100%;

  }
}

.active-pills {
  background: #ffffff;
  color: #555C74;
  border-radius: 50px;
}

.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.dropdown-menu {
  box-shadow: 0px 4px 8px 0px #2E2E2E1A, 0px 15px 15px 0px #2E2E2E17, 0px 35px 21px 0px #2E2E2E0D, 0px 62px 25px 0px #2E2E2E03, 0px 96px 27px 0px #2E2E2E00;
  background: #ffffff;
  border-radius: 8px;
  border: none;
  left: -78px !important;
}
.dropdown-item {
  font-family: Lato;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
  text-align: left;
  color: #555C74;
  margin-bottom: 8px;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
}

.indicator {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 20px;
  h4 {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background: #F9FAFB;
    padding: 14px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    // line-height: 28px;
    text-align: center;
    color: #73839B;
  }
}

.prop-review-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    // margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.update-top-text {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 30px;
  h4 {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #555C74;
  }
  span {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #0033EA;
    text-decoration: underline;
    cursor: pointer;
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
  margin-top: 30px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
    cursor: pointer;
    text-decoration: underline;
  }
}
// .general-info-content {
//   div {
//     // margin-bottom: 10px;
//     h5 {
//       font-family: Lato;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 20px;
//       text-align: left;
//       color: #2B3352;
//       overflow-wrap: break-word;
//     }
//     h6 {
//       font-family: Lato;
//       font-size: 14px;
//       font-weight: 500;
//       line-height: 20px;
//       text-align: left;
//       color: #555C74;
//       overflow-wrap: break-word;
//     }
//     h6:last-child {
//       margin-bottom: 0;
//     }
//   }
// }
.tab-holder {
  border-bottom: 3px solid #E2E8F0;
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #555C74;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
  }
}
.activeTab {
  color: #0033EA !important;
  border-bottom: 2px solid #0033EA;
}


.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container2 {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}
.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
  padding: 5px 0;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

// Dynamic input starts here
.unit-count-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2B3352;
  }
  button {
    outline: 0;
    box-shadow: none;
    font-family: Lato;
    font-size: 16px;
    color: #555C74;
  }
}
.unit-text-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h6 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  button {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #0033ea;
    border-bottom: 1px solid #0033ea;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.addNewUnit {
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    margin-top: 10px;
  }
}

.dynamic-input {
  padding: 1rem 10px !important;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #57595a;
  font-size: 1rem;
  font-family: Lato;
  height: inherit;
  outline: 0;
  box-shadow: none;
  // height: 48px;
}
.prop-reviw-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
  }
}
.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.empty-state {
  text-align: center;
  background: #ffffff;
  padding: 20px 15px;
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #2B3352;
  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #555C74;
  }
}

// Dynamic input ends here

.upload-btn-id {
  p {
    color: #57595a;
    font-size: 14px;
    line-height: 1.8;
  }
  button {
    display: block;
    width: 100%;
    padding: 1rem 10px;
    border: 1px solid #d2d3d3;
    border-radius: 6px;
    color: #57595a;
    font-size: 1rem;
    font-family: Lato;
    height: 62px;
    margin-bottom: 0.5rem;
    outline: 0;
    box-shadow: none;
    white-space: nowrap;
  }
}

// Upload sesseions starts
.upload-holder {
  border: 1px solid #E4E7EC;
  padding: 15px;
  border-radius: 6px;
  // margin-bottom: 10px;
}
.holding-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  button {
    border: 1px solid #555C74;
    color: #555C74;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #2B3352;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    padding: 5px;
    display: inline-block;
    margin: 5px !important;
    margin-left: 0 !important;
    position: relative;
    span {
      padding: 4px 8px;
      border: 1px solid;
      border-radius: 50px;
      position: absolute;
      bottom: 72%;
      left: 93%;
    }
  }
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
}
.uploading-doc {
  color: #48b881 !important;
  text-align: center;
  margin-top: 20%;
  font-size: 14px !important;
  animation: blink 1s infinite; 
}
// Upload session ends

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

.green-yellow-btn {
  margin-top: 40px;
  button {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    min-height: 48px;
    min-width: 106px;
    outline: 0;
    box-shadow: none;
  }
  .save {
    background: #2BC300;
    margin-right: 20px;
  }
  .cancel {
    background: #EB0000;
  }
}

.percentage-holder {
  display: flex;
  align-items: center;
  span {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #555C74;
  }
}
.progress-bar {
  width: 40px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-fill {
  height: 100%;
  background-color: #4caf50;
  width: 0;
  transition: width 0.3s ease;
  border-radius: 15px;
}

.ellipsis-text {
  width: 150px;
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
}


// updating property images starts
.main-img {
    position: relative;
    .image-main {
        border-radius: 8px;
        height: 100%;
        max-height: 438px;
        min-height: 400px;
        object-fit: cover;
        // height: 195px;
    }
    section {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}
.update-image {
    width: 100%;
    height: 115px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}
.landlordid-image {
    max-width: 100%;
    object-fit: contain;
    height: auto;
    // margin: 20px;
}

.small-img {
    // min-height: 215px;
    // height: 100%;
    // width: 100%;
    img {
        border-radius: 8px;
        // max-height: 215px;
        object-fit: cover;
        height: 215px;
        // height: 100%;
        // min-height: 195px;
        // max-height: 215px;
    }
}

.plus {
    border: 1px solid;
    border-radius: 50px;
    padding: 4px 8px;
    font-size: 12px;
}
.view-image-holder {
    position: relative;
    span {
        position: absolute;
        right: 22px;
        top: 5px;
        cursor: pointer;
        i {
            color: #eb2020;
        }
    }
}

.view-id-btn {
   cursor: pointer;
    border-radius: 10px;
    border: 1px solid #2B3352;
    width: fit-content;
    padding: 5px 10px;
}

.file-display {
  position: relative;
  width: max-content;
  padding: 10px 15px;
  border: 1px solid #CCD6FB;
  margin-top: 15px;
  margin-right: 15px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  span {
    font-size: 10px;
    // bottom: 31px;
    // position: absolute;
    // right: -10%;
    margin-left: 15px;
    border: 1px solid #CCD6FB;
    border-radius: 50px;
    padding: 5px 8px;
    color: #2B3352;
    cursor: pointer;
    outline: 0;
    box-shadow: none;
  }
  h6 {
    color: #2B3352 !important;
    margin-bottom: 0 !important;
    font-size: 12px;
    font-family: Lato;
  }
}

.update-buttons {
    flex-wrap: nowrap;
    button {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        min-height: 48px;
        box-shadow: none;
        outline: 0;
    }
    .update {
        background: #0033EA;;
        color: #ffffff;
    }
    .cancel {
        color: #0033EA;
        border: 1px solid #0033EA;;
    }
    .cancel2 {
      // border: 1px solid #EB0000;
      color: #EB0000
    }
}
// Updating property inages ends


// Rent info modified 

/* General styles for the Gmail message */
.info-list {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  font-family: Arial, sans-serif;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.info-rent-holder:nth-child(odd) {
  background-color: #f9f9f9; /* Light gray for odd rows */
}

.info-rent-holder:nth-child(even) {
  background-color: #f5f5f5; /* White for even rows */
}

.info-list:hover {
  background-color: #eae7e7;
}

/* Styles for the circular icon */
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #4285f4;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 10px;
  text-transform: uppercase;
  flex-shrink: 0;
}

.icon span {
  display: block;
  text-align: center;
  line-height: 1.2;
}

/* Styles for the message content */
.info-content {
  flex: 1;
  min-width: 0;
}

.unit-name {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #202124;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 200;
  }
}

.info-desc {
  margin: 2px 0;
  font-size: 12px;
  color: #5f6368;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-date {
  font-size: 12px;
  color: #5f6368;
  white-space: nowrap;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Adjust the size of the circular icon */
  .icon {
    width: 34px;
    height: 34px;
    font-size: 14px;
  }

  /* Reduce the padding for smaller screens */
  .info-list {
    padding: 8px;
  }

  .unit-name {
    font-size: 13px;
  }

  .info-desc {
    font-size: 11px;
  }

  .info-date {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  /* Stack elements vertically on very small screens */
  .info-list {
    // flex-direction: column;
    // align-items: flex-start;
    gap: 10px;
  }

  .icon {
    margin-bottom: 8px;
    margin-right: 0;
  }

  .info-content {
    width: 100%;
  }

  .unit-name,
  .info-desc,
  .info-date {
    font-size: 12px;
  }
}



// Rent info modified ends



@media screen and (min-width: 1024px) {
  .steps-container {
    display: block;
  }
}

.forms-container {
  flex: 1;
}

@media screen and (max-width: 599px) { 
 
  .finish-holder {
    margin: 0;
  }
  #progressbar {
    padding-top: 30px;
  }
  #progressbar li {
    font-size: 9px;
  }
  .indicator { 
    gap: 10px;
    h4 {
      width: 40px;
      height: 40px;
      padding: 9px;
      font-size: 14px;
    }
  }
}

</style>